import React from "react";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";
import imageSuse from "../catalog/Suse.png";



export default function Katalog () {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
      return (
    <>
    {allDevices && (
            <NavBar />
        )}
          {isSmallDevice && (
              <NavBarMobile/>
        )}
      <div className="container mx-auto text-center min-h-screen">
            <div className="flex justify-start items-center">
                <h1 className="text-5xl text-blue-400 font-semibold mb-4 text-gray-800 pb-2">
                KATALOG VREMENSKIH I KLIMATSKIH EKSTREMA U SRBIJI
                </h1>
                

            </div>
            <div className="mx-auto text-left">
            <hr />
            <p>Uvodna napomena: EXTREMES katalog biće regularno ažuriran tokom perioda realizacije projekta pa su moguće relativno česte izmene i dopune u smislu sadržaja, pezentovanih informacija, slika i grafika. U tom smilsu pozivamo sve korisnike kataloga da povremeno proveravju da li je došlo do izmena i dopuna kako bi imali pristup najsvežijim informacijama.</p>
            <br/>
            </div>

            <div class="wrapper max-w-screen-xl mx-auto">
              <a href="/suse">
                  <div class="deoKatalog">
                    <div class="textKatalog">
                      <h2>SUŠE</h2>
                      <p>Osmotreni podaci i <br/>buduće projekcije i trendovi</p>
                      </div>
                      <img src={imageSuse} />
                  </div>
              </a>
                  <div class="deoKatalog hidden1">
                    <div class="textKatalog">
                      <h2>SUŠE</h2>
                      <p>Text text<br/>Text text</p>
                      </div>
                      <img src={imageSuse} />
                  </div>
                  <div class="deoKatalog hidden1">
                    <div class="textKatalog">
                      <h2>SUŠE</h2>
                      <p>Text text<br/>Text text</p>
                      </div>
                      <img src={imageSuse} />
                  </div>
                  <div class="deoKatalog hidden1">
                    <div class="textKatalog">
                      <h2>SUŠE</h2>
                      <p>Text text<br/>Text text</p>
                      </div>
                      <img src={imageSuse} />
                  </div>
                  <div class="deoKatalog hidden1">
                    <div class="textKatalog">
                      <h2>SUŠE</h2>
                      <p>Text text<br/>Text text</p>
                      </div>
                      <img src={imageSuse} />
                  </div>
                  <div class="deoKatalog hidden1">
                    <div class="textKatalog">
                      <h2>SUŠE</h2>
                      <p>Text text<br/>Text text</p>
                      </div>
                      <img src={imageSuse} />
                  </div>
                </div>
    </div>
    <Form />
    <Footer />
    </>
  );
}
